(function(){

    // Remove empty <p> tags created by tinymce
    const paragraph = document.querySelectorAll('p') || [];
    paragraph.forEach( e => (e.innerHTML.trim() === '') && e.parentNode.removeChild(e));


    $('.sym-slides').slick({
        accessibility: true
    });

    $('.images-slides').slick({
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 3000,
        speed: 500,
        fade: true,
        accessibility: true
    });

    $('.slider-slide').slick({
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 4000,
        speed: 700,
        fade: true,
        accessibility: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    adaptiveHeight: true,
                }
            }
        ]
    });

    $('.images-artist-one').slick({
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 3000,
        speed: 500,
        accessibility: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    });

    $('.images-artist-two').slick({
        arrows: false,
        dots: false,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 3000,
        speed: 500,
        accessibility: false,
        slidesToShow: 2,
        slidesToScroll: 1,
    });

    $('.reviews-slides').slick({
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 4000,
        speed: 700,
        fade: true,
        accessibility: true,
        adaptiveHeight: true
    });

    $('.gallery-slider').slick({
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 3000,
        speed: 500,
        accessibility: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    variableWidth: false,
                }
            }
        ]
    });

    $('.awards-slider').slick({
        accessibility: true,
        arrows: true,
        slidesToShow: 2, 
        responsive: [
            {
                breakpoint: 768, 
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: false
                }
            }
        ]
    });

    $('.slider-bg-content').slick({
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 700,
        fade: true,
        accessibility: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    adaptiveHeight: true,
                }
            }
        ]
    });

    let ScrollDown = document.getElementsByClassName('js-scrolldown');
    if(ScrollDown.length > 0){
        ScrollDown[0].addEventListener('click', function(){
            animateto('intro');
        });
    }

    //FAQ
    setTimeout(function(){
        $('.tab-label').each(function(){
            let thisHeightTap = $(this).height() + 40;
            let thisHeight = $(this).next('.tab-content').height() + thisHeightTap;
            $(this).parent().animate({height: thisHeightTap + 'px'},400);
            if($(this).hasClass('open')){
                $(this).parent().animate({height: thisHeight + 'px'},400);
            }
        });
        $('.learn-more-btn').append('<div class="arrow"></div>');
    }, 400);

    $('.tab-label').click(function(){
        let currentclick = $(this);
        let thisHeightTap = $(this).height() + 40;

        let slideIndex = $(this).data('artwork');
        $(".accordion-slide").slick('slickGoTo', parseInt(slideIndex));

        if(!currentclick.hasClass('open')){
            $('.tab-label').each(function(){
                if($(this) !== currentclick ){
                    if($(this).hasClass('open')){
                        let thisHeight = $(this).height() + 40;
                        $(this).removeClass('open');
                        $(this).parent().animate({height: thisHeight + 'px'},400);
                    }
                }
            });
            currentclick.addClass('open');
            let thisHeight = $(this).next('.tab-content').height() + thisHeightTap;
            $(this).parent().animate({height: thisHeight + 'px'},400);
        } else {
            currentclick.removeClass('open');
            currentclick.parent().animate({height: thisHeightTap + 'px'},400);
        }
    });

    $('[data-fancybox]').fancybox({
        loop : true,
        backFocus: false,
        autoFocus: false,
    });

    $("a[href^='#']:not(:where(a[data-fancybox], a[data-filter], a.accordion-toggle, a#js_btn_book, #js-expand-map))").click(function(e){
        e.preventDefault();
        let strAncla = $(this).prop('hash').substr(1);
        animateto(strAncla);
    });

    let screenHeight = $(window).height();
    $('#home .top-img figure, #home .top-img video').css("height", screenHeight);

    $(window).resize(function(){
        screenHeight = $(window).height();
        $('#home .top-img figure, #home .top-img video').css("height", screenHeight);
    });

    $('.btn:not(.attractions-map__expand), .signup-btn').each(function() {
        let htmlString = $(this).text();
        $(this).html('<span>'+htmlString+'</span><div class="wave"></div>');
    });

})();

function animateto(target){
    var gotoTarget = offset(document.getElementById(target)).top - 90;
    var from     = window.pageYOffset
    var to       = gotoTarget
    var duration = 1300;
    var start = new Date().getTime()

    //time interval
    var timer = setInterval(function() {
        var time = new Date().getTime() - start

        if(from <= to ){
            var y = easeInOutQuart(time, from, to - from, duration)
        } else {

            var y = easeInOutQuart(time, from, -(from - to), duration)
        }
        window.scrollTo(0, y)
        if (time >= duration) clearInterval(timer)

    }, 1000 / 60);

    window.scrollTo(0, from)

}
//ease movement
function easeInOutQuart(t, b, c, d) {
    if ((t /= d / 2) < 1) return c / 2 * t * t * t * t + b;
    return -c / 2 * ((t -= 2) * t * t * t - 2) + b;
}
function offset(el) {
    var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

/** ADA Tab Links Functionality for Keyboard Navigation in Menu **/


function updateMenuTabIndex() {
    const menu = document.querySelector('#navigationBox');
    const isMenuVisible = menu && menu.classList.contains('open');
    const elements = document.querySelectorAll('.privary-navigation__item a, .social-media a');

    elements.forEach(element => {
        element.setAttribute('tabindex', isMenuVisible ? '0' : '-1');
    });
}

document.addEventListener('DOMContentLoaded', () => {
    updateMenuTabIndex();

    document.addEventListener('focusin', updateMenuTabIndex);
    const menu = document.querySelector('#navigationBox');
    if (menu) {
        menu.addEventListener('transitionend', updateMenuTabIndex);
    }
});
